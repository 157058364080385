<!--
Here we show the simplest possible component which accepts a prop and renders it.
Learn more about components in the guide!
-->

<script setup>
import {ref, onMounted, inject} from 'vue'

const {listId} = inject('listIdentifyer')
const form = ref()
const checked = ref([])

const props = defineProps({
  list: Array,
  plateform: String,
  mail: String,
  mess: String
})

const verif = () => {
  const test = false
  console.log(checked.value.length)
  if (checked.value.length > 0) {
    form.value.submit()
  } else {
    alert("Merci de choisir au moins un morceau")
  }

}
</script>

<template>
  <form ref="form" action="/createFromAnother" method="POST" @submit.prevent="verif" @onsubmit="verif">
    <ul class="music-list">
      <!-- v-if="title.id != undefined" -->
      <li v-for="title in list" :key="title.id">
        <input type='checkbox' class='form-check-input margin_right' :value="title.id + '*' + title.titre"
               :id="title.id"
               name="morceau[]" v-model="checked">
        <label class="inline" :for="title.id">{{ title.titre + ' par ' + title.artist }}</label>
      </li>
    </ul>
    <label for="playlist_name">Nom de la playlist</label>
    <input type="text" name="nomPlaylist" required class="form-control" id="playlist_name">
    <input v-if="listId !== '-1'" type="checkbox" id="ajout-morceau" name="ajout-morceau">
    <label v-if="listId !== '-1' && listId !== '-2'" for="ajout-morceau" class="inline">Autoriser mes amis à ajouté des morceaux à cette playlist</label>
    <label v-if="listId === '-2'" for="ajout-morceau" class="inline">Autoriser l'autre personne à ajouté des morceaux à cette playlist</label>
    <input type="hidden" name='plateforme' :value="plateform">
    <input type='hidden' name='idListeAmis' :value="listId">
    <input type='hidden' name='mail' :value="mail">
    <input type='hidden' name='mess' :value="mess">
    <button class="btn btn-primary" type='submit'>Créer la playlist</button>
  </form>
</template>

<style>
.music-list {
  list-style: none;
}

.margin_right {
  margin-right: 10px;
}
</style>