<script setup>
import axios from "axios";
import {inject, onMounted, ref} from "vue";
import Loader from "../Loader"

const listeMesAmis = ref()
const choix = ref()
const loaderSelect = ref(true)
const afficherSelect = ref(true)
const afficherInputMail = ref(false)
const afficherMessage = ref(false)
const afficherBtnMessage = ref(true)
const email = ref('')
const message = ref('')

const afficherChoix = inject("afficherChoix")
// const choix = inject("choix")
const emit = defineEmits(["changerEtat"]);

onMounted(() => {
  axios.get("/apiTest/getMesAmis")
      .then(res => {
        listeMesAmis.value = res.data
        loaderSelect.value = false
        if (listeMesAmis.value.length === 0) {
          afficherSelect.value = false
          afficherInputMail.value = true
        }
      })
})


const actionChoix = () => {
  if (choix.value === "autre") {
    afficherInputMail.value = true
    email.value = ''
  }
  else if (choix.value === "null")
  {
    alert("Merci de choisir une option valide")
  }
  else
  {
    afficherMessage.value = true
    afficherInputMail.value = false
    email.value = choix.value
  }
}

const verifEmail = () => {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (email.value.match(validRegex)) {
    afficherMessage.value = true
    // choix.value = email.value
  } else {
    alert("Adresse mail invalide ")
  }
}

const envoieMessage = () => {
  afficherBtnMessage.value = false
  afficherChoix.value = true
  emit("changerEtat", {mail: email.value, mess: message.value})
}

const afficherBtnMessageModif = () => {
  afficherBtnMessage.value = true
}
</script>

<template>
  <Loader v-if="loaderSelect"></Loader>
  <div v-if="afficherSelect">
    <select class="form-select" v-model="choix" v-if="loaderSelect === false" @change="actionChoix">
      <option :value="'null'">---Choisir---</option>
      <option :value="ami.email" v-for="ami in listeMesAmis">
        {{ ami.pseudo + ' (' + ami.email + ')' }}
      </option>
      <option :value="'autre'">--Autre personne--</option>
    </select>
  </div>
  <div v-if="afficherInputMail">
    <label for="mail">Adresse mail de la personne à inviter : </label>
    <div class="input-group mb-3">
      <input type="email" class="form-control" id="mail" v-model="email">
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="verifEmail">OK</button>
    </div>
  </div>
  <div v-if="afficherMessage">
    <label for="messageId">Message qui s'affichera en haut de la love tape : </label>
    <textarea class="form-control" v-model="message" id="messageId" @input="afficherBtnMessageModif"></textarea>
    <button @click="envoieMessage" class="btn-primary btn" v-if="afficherBtnMessage">
      Message terminé
    </button>
  </div>
</template>


<style scoped>

</style>