<script setup>
import { onMounted, onUpdated, ref, inject, provide, onActivated } from "vue";
import axios from "axios";
import Song from "../song/Song.vue";
import SongV2 from "../song/SongV2.vue";
import {
  getPlayer,
  getPlaylistSongs,
  getPlaylistInfos,
  resetDetailsPlaylist,
} from "../../services/playlists";
import PlayerComponent from "../player/Player.vue";
import _ from "lodash";
import TitrePlaylist from "./TitrePlaylist";
import Player from "../player/Player.vue";
import SearchVue from "../search/Search.vue";
import SongInPlay from "../song/SongInPlay";
import CurrentSong from "../player/CurrentSong.vue";
import { setPlayerState } from "../../services/socket";
import socketIO from "../../utils/socketIO.vue";

const idStreaming = ref();
const isAdmin = ref();
const songPlayed = ref([]);
const songToPlay = ref([]);
const playStop = ref(false);
const playPause = ref(false);
const paused = ref(false);
const duree = ref(0);
const dureeDejaJouee = ref(0);
const progressBar = ref(false);
const key = ref(0);
const suivantClic = ref(false);
const showSearch = ref(false);
const affichageLecture = ref();
const affichageLu = ref([]);
const songInPlay = ref();
const idP = ref();
const enLecture = ref(false);
const endOfTrack = ref(false);
const lastTrack = ref("empty_track");
const showHistory = ref(false);
const orderInPlay = ref();
const currentPlayingSong = ref();
const durationCurrentSong = ref(0);
const playingSong = ref(false);
const currentSongComponent = ref();
const hiddenSongs = ref([]);
const ajoutPossible = ref(false)

let pretMorceauSuivant = false;

const props = defineProps({
  listId: String,
  player: Object,
  lectureList: Array,
});

const emit = defineEmits(["resetDetailsPlaylist"]);

defineExpose({
  isCurrentlyPlaying,
  stopSpotify,
});

const { user } = inject("user");
const { listId } = inject("listIdentifyer");
const { socket } = inject("socket");
const { songsList, setSongsList } = inject("songsList");
const { playlistInfos } = inject("playlistInfos");


provide("isAdmin", isAdmin);

onMounted(() => {
  // On check si l'user actuel est admin de la playlist
  // en cours au chargement de cette dernière
  checkIsAdmin();
  // On vérifie si la playlist autorise l'ajout de titre
  if (playlistInfos.value.ajoutLibre || isAdmin.value) {
    ajoutPossible.value = true
  }
  else {
    ajoutPossible.value = false
  }
  if (window.innerWidth < 600) {
    document.getElementById("headFixe").style.maxHeight = (window.innerHeight - 335) + "px";
  }

});

onUpdated(() => {
  // On check si l'user actuel est admin de la playlist
  // vers laquelle on vient de basculer
  // checkIsAdmin();


  console.log(ajoutPossible.value)
  // console.log("je suis dans onUpdated")
});

function isCurrentlyPlaying() {
  return playStop.value;
}

// Check si le user actuel est admin de la
// playlist actuelle(à changer peut être)
const checkIsAdmin = () => {
  user.value.playlists.filter((playlist) => playlist.id == listId.value)
    .length > 0
    ? (isAdmin.value = true)
    : (isAdmin.value = false);
};

/**
 * Lis le morceau en tête
 */
const playSong = () => {
  console.log("début du morceau")
  // On stock les morceaux qu'il reste à lire dans songToPlay
  // en faisant la différence entre la playlist principale
  // et la liste des morceaux lus
  songToPlay.value = _.difference(props.lectureList, songPlayed.value);

  // Reset du visuel et ça permet aussi de garder la bonne valeur
  // playpause sur le serveur
  endOfTrack.value = false;
  playPause.value = false;

  // S'il y a un morceau au moins à lire
  if (songToPlay.value.length > 0) {
    const deviceId = sessionStorage.getItem("deviceId");

    // On lance la lecture du morceau en tête de songsToPlay => [0]
    getPlayer().then((res) => {
      idStreaming.value = res.idStreaming;
      axios
        .put(
          "https://api.spotify.com/v1/me/player/play?device_id=" + deviceId,
          {
            uris: [songToPlay.value[0]],
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + idStreaming.value,
            },
          }
        )
        .then((response) => {

          props.player.togglePlay()
          props.player.togglePlay()

          orderInPlay.value = Object.keys(props.lectureList).find(
            (order) => props.lectureList[order] === songToPlay.value[0]
          );

          currentPlayingSong.value = songsList.value[orderInPlay.value];

          playingSong.value = true;
          console.log("play song response ", response)
          // Mise à jours du visuel du player
          playStop.value = true;
        });
    });
  } else {
    // Not playing anymore
    playingSong.value = false;

    // Reset du player sur le serveur
    setPlayerState(socket, listId.value);

    // Fin de la lecture
    alert("the end !");

    // Mise à jours visuelle du player
    songPlayed.value = [];
    // progressBar.value = false

    playStop.value = false;
  }
};

// Interception des evenements du player
if (props.player) {
  console.log("song to play", songToPlay.value)
  props.player.addListener("player_state_changed", (state) => {

    // Début du morceau en tête
    if (state.position === 0 && state.paused === false) {
      if (!progressBar.value) {
        duree.value = state.duration;
        durationCurrentSong.value = state.duration;

        // Si le morceau n'a pas encore été lu le mettre dans la liste des morceaux lus
        // Pour eviter les doublons
        let songsAlreadyPlayed =
          currentSongComponent.value.sendPlayer().songsPlayed;
        if (currentPlayingSong.value)
          if (!songsAlreadyPlayed.includes(currentPlayingSong.value.idMorceau)) {
            songsAlreadyPlayed = [
              ...songsAlreadyPlayed,
              currentPlayingSong.value.idMorceau,
            ];
          }

        // Envoi signal au serveur socket et met à jours l'état de son Player
        setPlayerState(socket, listId.value, {
          song: currentPlayingSong.value,
          playing: true,
          paused: false,
          progress: 0,
          duration: durationCurrentSong.value,
          likes: [],
          songsPlayed: songsAlreadyPlayed,
        });
      }
    }

    // Quand on reçoit l'evenement de fin
    // if (endOfTrack.value && state.position == 0 && state.paused){
    if (state.track_window.previous_tracks.length > 0 && state.track_window.previous_tracks[0].uid != lastTrack.value) {

      lastTrack.value = state.track_window.previous_tracks[0].uid
      // On met le morceau joué dans la liste des morceaux joués
      if (!songPlayed.value.includes(songToPlay.value[0]))
        songPlayed.value.push(songToPlay.value[0]);

      // Relancer la lecture
      pretMorceauSuivant = false
      playSong();
    }
  });
}

// Stoppe la lecture en cours
function stopSpotify() {
  const deviceId = sessionStorage.getItem("deviceId")
  getPlayer().then((res) => {
    axios
      .put(
        "https://api.spotify.com/v1/me/player/pause?device_id=" + deviceId,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + idStreaming.value,
          },
        }
      )
      .then(() => {
        // Not playing anymore
        playingSong.value = false;

        // Mise à jours des visuelles du player
        playStop.value = false

        // Reset des morceaux joués
        songPlayed.value = [];

        setPlayerState(socket, listId.value);
      });
  });
}

/**
 * Toggle Play Pause et visuel du player
 */
const pauseSpotify = () => {
  props.player.togglePlay();
  playPause.value = !playPause.value;
  paused.value = !paused.value;

  // Not playing anymore
  playingSong.value = false;

  setPlayerState(socket, listId.value, {
    song: currentPlayingSong.value,
    playing: true,
    paused: playPause.value,
    progress: currentSongComponent.value.sendProgress(),
    duration: durationCurrentSong.value,
    likes: [],
    songsPlayed: currentSongComponent.value.sendPlayer().songsPlayed,
  });
};

/**
 * Morceau suivant
 */
const suivantSpotify = () => {
  if (songToPlay.value.length === 1) {
    alert("Pas de morceau suivant");
  } else {
    songPlayed.value.push(songToPlay.value[0]);
    suivantClic.value = true;

    // Reset play pause après next
    playPause.value = false;

    playSong();
  }
};

/**
 * Sauvegarde de la playlist
 */
const savePlaylist = () => {
  // Sauvegarde de la playlist
  if (
    window.confirm(
      "Es-tu sûr de vouloir sauvegarder cette playlist (l'ancienne version sera écrasée) ?"
    )
  ) {
    let idDetails = [];
    songsList.value.forEach((song) => {
      idDetails = [...idDetails, song.idDetails];
    });
    axios
      .post("/detailsPlaylistSauvegarder", { idDetails: idDetails })
      .then((res) => {
        if (res.data == 200) {
          alert("Sauvegarde effectuée");
        }
      });
  }
};

// Ludo c'est à toi
const nouveauJukebox = () => {
  let nomPlaylistDefaut =
    playlistInfos.value.nom + " (" + new Date().toLocaleString("fr-FR") + ")";

  let nomPlaylist = window.prompt(
    "Donnes un nom à la nouvelle playlist :",
    nomPlaylistDefaut
  );
  let infosSongs = [];
  songsList.value.forEach((song) => {
    infosSongs = [...infosSongs, song.idMorceau];
  });
  if (nomPlaylist.length < 1) {
    nomPlaylist = nomPlaylistDefaut;
  }

  axios
    .post("/jukeboxFromAnother", {
      nomPlaylist: nomPlaylist,
      infosSongs: infosSongs,
    })
    .then((res) => {
      if (res.status === 202) {
        alert(res.data);
      }
    });
};

/**
 * Reset de la playlist
 */
const resetPlaylist = async () => {
  if (window.confirm("Es-tu sûr de vouloir réinitialiser ?")) {
    emit("resetDetailsPlaylist", {
      listId,
      songsList,
      setSongsList,
      socket,
      stopSpotify,
    });

    // Not playing anymore
    playingSong.value = false;
  }
};

// Affichage de la modal de recherche
const searching = () => {
  showSearch.value = !showSearch.value;

  //Fix zindex du panneau de navigation quand on affiche la recherche
  if (showSearch.value) {
    $("#nav-tabs-wrapper-id").css("z-index", "0");
  }
};

const setPlayer = (e) => {
  hiddenSongs.value = e.songsPlayed;
  currentPlayingSong.value = e.song;
};

const closeSearch = () => {
  showSearch.value = !showSearch.value;
  $("#nav-tabs-wrapper-id").css("z-index", "1");
}
</script>

<template>
  <TitrePlaylist v-if="playlistInfos" :infos="playlistInfos"></TitrePlaylist>
  <div v-if="hiddenSongs.length > 1" class="historique">
    Afficher historique :
    <label class="switch">
      <input type="checkbox" id="historiqueId" name="historique" v-model="showHistory" />
      <span class="slider round"></span>
    </label>
  </div>
  <div class="message-love" v-if="playlistInfos.message">
    <table class="table-list-playlist nostyle">
      <tr>
        <th>
          Message de : {{ user.pseudo }}
        </th>
      </tr>
      <td>
        {{ playlistInfos.message }}
      </td>
    </table>
  </div>

  <div class="playlist-wrapper">

    <div class="tableHeader">
      <table class="tableListHeader nostyle">
        <thead>
          <tr>
            <th scope="col" class="alignLeft">avatar</th>
            <th scope="col" class="alignCenter">titre</th>
            <th scope="col" class="alignRight">likes</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <CurrentSong ref="currentSongComponent" :duration="durationCurrentSong" @emit-player="setPlayer"></CurrentSong>
        </tbody>
      </table>
    </div>

    <div class="headFixe" id="headFixe">
      <table class="table-list-playlist nostyle">
        <tbody name="fade" is="vue:transition-group" class="tbBody">
          <tr v-for="(song, key) in songsList" :key="song" :class="{
            displayNone:
              (hiddenSongs.includes(song.idMorceau) && !showHistory) ||
              (currentPlayingSong &&
                currentPlayingSong.idMorceau == song.idMorceau),
            playedSong: hiddenSongs.includes(song.idMorceau) && showHistory,
          }">
            <SongV2 :order="key" :data="song" :key="song"
              :lecture="'spotify:track:' + song.idSpotify === affichageLecture"
              :invisible="affichageLu.includes('spotify:track:' + song.idSpotify)"></SongV2>
          </tr>

        </tbody>
      </table>
    </div>
    <table class="tableAjoutMorceau">
      <tr class="add-track-wrapper" v-if="ajoutPossible">
        <div @click="searching" class="add-track-container">
          <font-awesome-icon icon="fa-solid fa-circle-plus" />
          <span>Rajoute ton morceau</span>
        </div>
      </tr>
    </table>
  </div>


  <SearchVue :show="showSearch" @close="closeSearch"></SearchVue>
  <div v-if="isAdmin || playlistInfos.typePlaylist.id !== 2">
    <PlayerComponent @player-stop="stopSpotify" @player-play="playSong" @player-pause="pauseSpotify"
      @player-next="suivantSpotify" @player-reset="resetPlaylist" @player-save="savePlaylist" :playStop="playStop"
      :playPause="playPause" />
  </div>

  <socketIO :socket="socket"></socketIO>
</template>

<style>
.tableAjoutMorceau {
  border: 0;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}

.tableHeader {
  /* margin-top: 20px; */
}

.tableListHeader {
  border: 0;
  background-color: rgb(113, 32, 125);
  border-radius: 10px 10px 0 0;
}

.tableListHeader th {
  border: 0;
}

.tableListHeader td {
  border: 0;
}

.tableListHeader tr:first-child {
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.378); */
}

.tableListHeader tr th:first-child {
  padding: 10px 0 10px 10px;
}

.tableListHeader tr {
  padding: 5px 12px !important;
}

.alignCenter {
  text-align: center;
  width: 70%;
}

.alignLeft {
  text-align: center;
  width: 15%;
}

.alignRight {
  text-align: center;
  width: 15%;
}

.headFixe {
  overflow-y: auto;
}

.lectureTR {
  background-color: rgba(255, 255, 255, 0.3);
}

.playedSong {
  opacity: 0.5;
}

.displayNone {
  display: none !important;
}

.add-track-wrapper {
  width: 100%;
  position: relative;
  height: 65px;
  display: flex;
  /* border-top: 1px solid rgba(255, 255, 255, 0.378); */
  background-color: rgba(255, 255, 255, .2);
}

.add-track-wrapper:hover {
  background-color: #a63db4;
}

.add-track-container {
  width: 100%;
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  height: 100%;
  margin-top: 0;
}

.add-track-container * {
  transition: 0.1s;
}

.add-track-container:hover svg {
  color: #7df7f7;
  transition: .3s;
  /* height: 45px; */
}

.add-track-container:hover span {
  color: #7df7f7;
  font-size: 1.3rem;
  transition: .3s;
  /* height: 45px; */
}

.add-track-container svg {
  height: 30px;
  margin: 0;
  transition: .3s;
}

.add-track-container span {
  /* height: 30px; */
  margin: 0 0 0 15px;
  transition: .3s;
}

.table-list-playlist {
  border: 0;
  background-color: rgba(255, 255, 255, .2);
}

.table-list-playlist th {
  border: 0;
}

.table-list-playlist td {
  border: 0;
}

.table-list-playlist tr {
  padding: 5px 12px !important;
  border-bottom: 1px solid #532a59;
  ;
}

.table-list-playlist tr * {
  text-align: center;
}

/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.1s cubic-bezier(0, 0, 0.1, 0.1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}

.rouge {
  background-color: red;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D4CDD5;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: #310A36;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #44b3b5;
}

input:focus+.slider {
  box-shadow: 0 0 1px #44b3b5;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.historique {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0;
}
</style>
