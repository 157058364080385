<script setup>

import { ref, provide, onMounted, onUpdated } from "vue"

const hiddenPlay = ref(false)

const props = defineProps({
    socket:Object
})



</script>
