<template>
	<h1 style="position: sticky;top:55px;z-index: 1;" class="centrer titre">{{ infos.nom || '' }}</h1>
</template>
<!-- <p class="centrer italic">({{ infos.utilisateur.pseudo || '' }})</p> -->

<script setup>
import { inject } from "vue";
const props = defineProps({
	infos: Object
})


</script>

<style scoped>
.titre-playlist {
	margin-top: 20px;
	margin-bottom: 0px;
	font-size: 1.3rem;
	z-index: 999;
}

.titre {
	background-color: #6a2b79;
	border-radius: 20px;
	margin: 0 10%;
	padding: 5px 0;
	align-self: center;
	animation: flashing2 2s infinite alternate;
}

@media only screen and (min-width: 600px) {
	@keyframes flashing {
		from {
			box-shadow:
				0 0 8px #fff,
				inset 0 0 8px #fff,
				0 0 32px #c6e2ff,
				inset 0 0 32px #c6e2ff,
				0 0 64px #c6e2ff,
				inset 0 0 64px #c6e2ff;
		}
	
		to {
			box-shadow:
				0 0 8px #ffffff,
				inset 0 0 8px #ffffff,
				0 0 32px #44b3b5,
				inset 0 0 32px #44b3b5,
				0 0 64px #44b3b5,
				inset 0 0 64px #44b3b5;
		}
	}
	
	@keyframes flashing2 {
		from {
			box-shadow:
				0 0 8px #fff,
				inset 0 0 8px #fff,
				0 0 16px #44b3b5,
				inset 0 0 16px #44b3b5,
				0 0 32px #44b3b5,
				inset 0 0 32px #44b3b5;
	
			color: #c6e2ff;
		}
	
		to {
			box-shadow:
				0 0 4px #ffffff,
				inset 0 0 4px #ffffff,
				0 0 2px #44b3b5,
				inset 0 0 2px #44b3b5,
				0 0 4px #44b3b5,
				inset 0 0 4px #44b3b5;
	
			color: white;
		}
	}
}

@media only screen and (max-width: 600px) {
	.titre {
		background-color: #12B7B7;
		color: white;
		border-radius: 0 0 7px 7px;
		padding: 5px 0;
		align-self: center;
		font-weight: bold;
		margin: 0 auto;
		width: 100vw;
		margin-left: -12px;
	}
}
</style>