<script setup>
import { ref, onMounted, inject } from 'vue'
import axios from 'axios';

const likeState  = ref()
const { listId } = inject('listIdentifyer')
const { socket } = inject('socket')
const { user }   = inject('user')

const props = defineProps({
    data: Object,
    order: String,
})

const like = (e) => {
    likeState.value = "btn-primary"
    console.log(props.order);
    // Envoi du like à la bdd
    axios.post('/avis/like/' + listId.value, { morceauId: Number(props.data.idMorceau) }).then(res => {
        console.log(res);
    })

    // Envoi du like au serveur socket
    socket.value.emit('like-song', { playlistId: listId.value, morceauId: props.data.idMorceau, user: user.value, order: props.order }, (isLiked) => {
        console.log(isLiked)
    });
}

</script>

<template>
    <img class="songs-avatar" :src="data.cover">
    <p class="songs-title">{{ data.titre }}</p>
    <button :class="likeState + ' like-btn'" @click="like">like</button>
</template>

<style>
.songs-avatar {
    width: 50px;
}

.songs-title {
    display: inline;
    padding-left: 5px;
}

.like-btn {
    display: inline;
}
</style>