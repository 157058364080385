<script setup>
import {ref, onMounted, provide} from 'vue'
import axios from 'axios'
import TitleList from '../titleList/TitleList.vue'
import Loader from "../Loader";
import SelectAmis from "../amis/SelectAmis";

const playlists = ref([])
const selectedPlaylist = ref('0')
const listTitles = ref([])
const plateform = ref()
const loaderSelect = ref(true)
const loaderList = ref(false)
const afficherChoix = ref(false)
const email = ref('')
const message = ref('')

const props = defineProps({
  platformPlaylists: Object,
})

provide("afficherChoix", afficherChoix)
// provide("choix", choix)

onMounted(() => {
  axios.get('/apiTest/playlists').then(response => {
    playlists.value = response.data
  })
      .then(res => {
        loaderSelect.value = false
      })
})

const changerEtat = (data) => {
  email.value = data.mail
  message.value = data.mess
}

const loadTitles = (e) => {
  loaderList.value = true
  axios.get('/apiTest/getMorceaux/' + selectedPlaylist.value).then(response => {
    listTitles.value = response.data
    plateform.value = listTitles.value[listTitles.value.length - 1].platform
  }).then(res => loaderList.value = false)
}

</script>

<template>
  <h2>Crée ta love tape</h2>
  <p>A qui est destiné la love tape ? :</p>
  <SelectAmis @changerEtat="changerEtat"></SelectAmis>
  <div v-if="afficherChoix">
    <p> choisis ta playlist à partir de ta plateforme favorite</p>
    <Loader v-if="loaderSelect"></Loader>
    <select class="form-select" v-model="selectedPlaylist" @change="loadTitles" v-if="loaderSelect === false">
      <option :value="0">---- choisir ----</option>
      <option :value="playlist.plateforme + '-' + playlist.id" v-for="playlist in playlists">
        <p>{{ playlist.nom + ' - ' + playlist.nbTrack + ' morceaux' }}</p>
      </option>
    </select>
    <Loader v-if="loaderList"></Loader>
    <TitleList v-if="selectedPlaylist != 0" :list="listTitles" :plateform="plateform" :mail="email" :mess="message"></TitleList>
  </div>
</template>