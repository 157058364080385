import axios from 'axios'

const initPlayer = async () => {
    let res = await axios.get('/apiTest/hasPlateform/Spotify')
    if (res.data) {
        const urlTestToken = "/apiTest/expirationSpotify"
        let token = await axios.get(urlTestToken)

        window.onSpotifyWebPlaybackSDKReady = () => {

            const player = new window.Spotify.Player({
                name: 'bandwidz',
                getOAuthToken: cb => { cb(token.data); },
                volume: 0.5
            });
            // Ready
            /* player.addListener('ready', ({ device_id }) => {
                console.log('Ready with Device ID', device_id);
                sessionStorage.setItem('deviceId', device_id)
            });

            // Not Ready
            player.addListener('not_ready', ({ device_id }) => {
                console.log('Device ID has gone offline', device_id);
            });

            // Errors
            player.addListener('initialization_error', ({ message }) => {
                console.error(message);
            });

            player.addListener('authentication_error', ({ message }) => {
                console.error(message);
            });

            player.addListener('account_error', ({ message }) => {
                console.error(message);
            });

            player.connect(); */
            return player
        };

        return window.onSpotifyWebPlaybackSDKReady()


    }
}

export default initPlayer
