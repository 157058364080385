<script setup>

import {ref, provide, onMounted, onUpdated} from "vue"

const hiddenPlay = ref(false)

const props = defineProps({
  playStop: Boolean, // false
  playPause: Boolean,// false
})

const hidePlay = () => {
  console.log('hideplay')
  hiddenPlay.value = true
}

const resetPlay = () => {
  console.log('reset play')
  hiddenPlay.value = true
}

onUpdated(() => {
  if (props.playStop && !props.playPause) {
    hiddenPlay.value = true
  } else {
    hiddenPlay.value = false
  }
})

</script>

<template>
  <div class="player-container">
    <div :class="{ 'player-wrapper playing': hiddenPlay, 'player-wrapper': !hiddenPlay }">
      <div @click="$emit('playerReset')" class="player-buttons reset-button">
        <font-awesome-icon icon="fa-solid fa-arrow-rotate-left"/>
      </div>
      <div @click="$emit('playerStop');" class="player-buttons stop-button">
        <font-awesome-icon icon="fa-solid fa-stop"/>
      </div>
      <div v-if="!playStop" @click="$emit('playerPlay');" class="player-buttons play-button">
        <font-awesome-icon icon="fa-solid fa-play"/>
      </div>
      <template v-else>
        <div @click="$emit('playerPause')" v-if="playPause" class="player-buttons pause-button glowing">
          <font-awesome-icon icon="fa-solid fa-play"/>
        </div>
        <div v-else @click="$emit('playerPause');" class="player-buttons play-button glowing">
          <font-awesome-icon icon="fa-solid fa-pause"/>
        </div>
      </template>
      <div @click="$emit('playerNext')" class="player-buttons next-button">
        <font-awesome-icon icon="fa-solid fa-forward-step"/>
      </div>
      <div @click="$emit('playerSave')" class="player-buttons save-button">
        <font-awesome-icon icon="fa-solid fa-floppy-disk"/>
      </div>
    </div>
  </div>
</template>

<style>
.player-container * {
  transition: .2s;
}

.player-container {
  position: relative;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
	margin-top: 20px;
}

.player-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 20px;
  /* width: 260px; */
  text-align: center;
  /*background: #310A36;*/
  background: #12B7B7;
  border-radius: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 600px) {
  .player-wrapper {
    position: fixed;
    bottom: 0;
    top: unset;
  }
}

.player-buttons {
  padding: 15px;
}

.player-buttons svg {
  height: 30px;
}

.player-buttons:hover svg {
  color: #310A36;
  /* height: 33px; */
}

.player-buttons.reset-button {
  margin-right: 15px;
  border-radius: 100px;
  height: 50px;
  width: 50px;
}

.player-buttons.reset-button svg {
  height: 20px;
  color: #D4CDD5;
}

.player-buttons.reset-button:hover {
  background-color: white;
}

.player-buttons.reset-button:hover svg{
  color: #12B7B7;
}

.player-buttons.save-button {
  margin-left: 15px;
  border-radius: 100px;
  height: 50px;
  width: 50px;
}

.player-buttons.save-button svg {
  height: 20px;
  color: #D4CDD5;
}

.player-buttons.save-button:hover{
  background-color: white;
}

.player-buttons.save-button:hover svg{
  color: #12B7B7;
}

@keyframes glowing {
  0% {
    color: white;
    filter: drop-shadow(0 0 5px rgb(246, 9, 9));
  }

  25% {
    color: white;
    filter: drop-shadow(0 0 5px rgb(255, 255, 0));
  }

  50% {
    color: white;
    filter: drop-shadow(0 0 5px rgb(0, 255, 55));
  }

  75% {
    color: white;
    filter: drop-shadow(0 0 5px rgb(43, 0, 255));
  }

  100% {
    color: white;
    filter: drop-shadow(0 0 5px rgb(255, 0, 251));
  }
}

@keyframes playing {
  0% {
    color: white;
    box-shadow: 0 0 2px rgb(246, 9, 9);
  }

  25% {
    color: white;
    box-shadow: 0 0 2px rgb(255, 255, 0);
  }

  50% {
    color: white;
    box-shadow: 0 0 2px rgb(0, 255, 55);
  }

  75% {
    color: white;
    box-shadow: 0 0 2px rgb(17, 0, 255);
  }

  100% {
    color: white;
    box-shadow: 0 0 2px rgb(255, 0, 251);
  }
}

.glowing svg {
  animation: glowing 3000ms infinite;
  height: 35px;
}

.playing {
  animation: playing 6000ms infinite;
}
</style>