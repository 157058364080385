<script setup>

import { ref, provide, onMounted } from "vue"
import { socketConnect } from "./services/socket"
import initPlayer from "./services/playerSpotify"
import PlateFormeList from "./components/plateformList/PlateFormeList.vue"
import PlaylistVue from "./components/playlist/Playlist.vue"
import Search from "./components/search/Search"
import { getPlaylistSongs, initPlaylist, getPlaylistInfos, resetDetailsPlaylist } from "./services/playlists"
import axios from "axios"
import Loader from "./components/Loader";
import { setPlayerState } from "./services/socket";
import PlateFormeListLoveTape from "./components/plateformList/PlateFormeListLoveTape";

const socket = ref()
const showPlaylist = ref(false)
const showPlayListCreation = ref(false)
const showLoveTapeCreation = ref(false)
const listId = ref()
const playlistComponent = ref()
const songsList = ref([])
const lectureList = ref([])
const user = ref()
const likesList = ref([])
const isAdmin = ref(false)
const afficherBouton = ref(false)
const afficherLoader = ref(false)
const playlistInfos = ref()
const player = ref()
const proceed = ref(false)

// const player = ref()

onMounted(() => {
  initPlayer().then(p => {
    player.value = p
    player.value.addListener('ready', ({ device_id }) => {
      console.log('Ready with Device ID', device_id);
      sessionStorage.setItem('deviceId', device_id)
    });

    // Not Ready
    player.value.addListener('not_ready', ({ device_id }) => {
      console.log('Device ID has gone offline', device_id);
    });

    // Errors
    player.value.addListener('initialization_error', ({ message }) => {
      console.error(message);
    });

    player.value.addListener('authentication_error', ({ message }) => {
      console.error(message);
    });

    player.value.addListener('account_error', ({ message }) => {
      console.error(message);
    });

    player.value.connect();

  })
})

provide("playlistInfos", {
  playlistInfos
})

// Sauvegarde de la liste des morceaux dans un context
provide("songsList", {
  songsList,
  setSongsList
})

// Sauvegarde de la playlist dans un context
provide("listIdentifyer", {
  listId,
  setListId
})
// Sauvegarde du socket dans un context
provide("socket", {
  socket,
  setSocket
})
// Sauvegarde du user dans un context
provide("user", {
  user,
  setUser
})

provide("playlistInfos", { playlistInfos })

//Fonction de mutation de la liste des morceaux
function setSongsList(newSongsList) {
  songsList.value = newSongsList
}

// Fonctions de mutations de la playlist
const setListId = (newListId) => {
  listId.value = newListId
}

// Fonctions de mutations du socket
const setSocket = (newSocket) => {
  socket.value = newSocket
}

// Fonctions de mutations du user
const setUser = (newUser) => {
  user.value = newUser
}

// Charge la playlist
const watchPlaylist = async () => {
  let qrId = document.getElementById("vuePlayList").getAttribute("qrId")

  // Si on est sur un Jukebox
  if (qrId) {
    afficherLoader.value = true
    playlistInfos.value = await getPlaylistInfos(qrId)
    initPlaylist(
      qrId,
      afficherBouton,
      socket,
      setUser,
      setListId,
      setSocket,
      socketConnect,
      listId,
      user,
      setSongsList,
      songsList,
      lectureList,
      showPlaylist,
      showPlayListCreation,
      afficherLoader,
      playlistInfos,
    )
    // Autoswitch to playlist tab on mobile
    if (window.mobileCheck()) {
      document.getElementById("playlist-tab").click()

    }
  }
  let playlists = document.querySelectorAll(".playlistToLoad")
  playlists.forEach(playlist => {

    // Au clique sur une playlist à gauche
    playlist.addEventListener("click", async (e) => {

      // Check si on ne click pas sur la même playlist
      if (listId.value == undefined) proceed.value = true
      else if (listId.value != playlist.getAttribute("room")) {
        if (!playlistComponent.value.isCurrentlyPlaying()) {
          proceed.value = true
        } else {
          if (window.confirm("La lecture s'arrêtera si vous changez de playlist")) {
            proceed.value = true
            playlistComponent.value.stopSpotify()
            setPlayerState(socket, listId.value)
          } else {
            proceed.value = false
          }
        }
      } else {
        proceed.value = false
      }

      if (proceed.value) {
        afficherLoader.value = true
        playlistInfos.value = await getPlaylistInfos(playlist.getAttribute("room"))
        console.log('playlist infos : ', playlistInfos.value);
        initPlaylist(
          playlist.getAttribute("room"),
          afficherBouton,
          socket,
          setUser,
          setListId,
          setSocket,
          socketConnect,
          listId,
          user,
          setSongsList,
          songsList,
          lectureList,
          showPlaylist,
          showPlayListCreation,
          afficherLoader,
          playlistInfos
        )
      }

      // Autoswitch to playlist tab on mobile
      if (window.mobileCheck()) {
        document.getElementById("playlist-tab").click()

      }
    })
  })
}

// Charge le form de création de playlist
const createPlaylist = () => {
  let createPlaylists = document.querySelectorAll(".create_playlist_button")
  createPlaylists.forEach(createPlaylist => {
    createPlaylist.addEventListener("click", (e) => {
      listId.value = createPlaylist.getAttribute("listId")
      if (listId.value === "-2") {
        showLoveTapeCreation.value = true
        showPlayListCreation.value = false
      }
      else {
        showPlayListCreation.value = true
        showLoveTapeCreation.value = false
      }
      showPlaylist.value = false

      // Autoswitch to playlist tab on mobile
      if (window.mobileCheck()) {
        document.getElementById("playlist-tab").click()
      }
    })
  })
}

const resetPlaylist = (resetData) => {
  resetDetailsPlaylist(resetData.listId, resetData.songsList, resetData.setSongsList, resetData.socket, lectureList, resetData.stopSpotify, playlistInfos.value.utilisateur)
}

watchPlaylist()
createPlaylist()

</script>

<template>
  <component is="script" src="https://sdk.scdn.co/spotify-player.js"></component>
  <Loader v-if="afficherLoader"></Loader>
  <PlaylistVue ref="playlistComponent" @reset-details-playlist="resetPlaylist" :lectureList="lectureList" :player="player"
    :songsList="songsList" :listId="listId" v-if="showPlaylist">
  </PlaylistVue>
  <PlateFormeList v-if="showPlayListCreation"></PlateFormeList>
  <PlateFormeListLoveTape v-if="showLoveTapeCreation"></PlateFormeListLoveTape>
</template>

<style>
</style>
