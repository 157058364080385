<template>
  <div class="ligne-morceau-ajouter" @click="ajoutMorceauDansPlaylist">
    <td style="width:20%">
      <img :src="track.cover" class="songs-avatar">
    </td>
    <td  style="width:80%">
      <p class="songs-title">{{ track.titre }} by {{ track.artiste }}</p>
    </td>
    <!-- <td v-if="track.preview_url === null">No preview</td> -->
    <!--  <td v-else>-->
    <!--    <a v-bind:href="track.preview" target="_blank">Preview</a>-->
    <!--  </td>-->
    <!-- <td>
      <button class="boutonInvisible" >
        <img :src="icon">
      </button>
    </td> -->
  </div>
</template>

<script setup>

import icon from "/public/icon/add_bleu.svg"
import axios from "axios";
import { inject } from "vue";

const { socket } = inject('socket')

const props = defineProps({
  idPlaylist: String,
  index: Number,
  track: Object
})
const emit = defineEmits(['effacer'])

const ajoutMorceauDansPlaylist = () => {
  axios
    .post("/ajoutMorceauIsrc", { id_playlist: props.idPlaylist, track: props.track.isrc })
    .then(res => {
      socket.value.emit("add-track-to-playlist", { song: res.data.song[0], playlistId: res.data.idPlaylist })
    })
  emit('effacer');
}
</script>

<style scoped>
.ligne-morceau-ajouter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 5px;
  border-radius: 15px;
  margin-bottom: 10px;
  margin-right: 4px;
}
.ligne-morceau-ajouter:hover, .ligne-morceau-ajouter:active {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.songs-avatar {
  width: 50px;
  padding: 10px 0 10px 0;
}

.songs-title {
  display: inline;
  padding-left: 5px;
}

.like-btn {
  display: inline;
}

.songs-likes {
  margin: 0;
}

td {
  border: 0;
}
</style>