<script setup>
import { ref, onMounted, onUpdated, inject } from "vue";
import axios from "axios";
import trash from "/public/icon/trash.svg";
import heart from "/public/icon/heart.svg";

const likeState = ref("not-liked");
const { listId } = inject("listIdentifyer");
const { socket } = inject("socket");
const { user } = inject("user");
const idPlaylist = inject("listIdentifyer");
const isAdmin = inject("isAdmin");

const props = defineProps({
  data: Object,
  order: Number,
  idPlaylist: String,
  lecture: Boolean,
  invisible: Boolean,
});

const like = (e) => {
  // console.log(props.order);
  // Envoi du like à la bdd
  // axios.post('/avis/like/' + listId.value, { morceauId: Number(props.data.idMorceau) }).then(res => {
  //     console.log(res);
  // })

  // Envoi du like au serveur socket
  socket.value.emit(
    "like-song",
    {
      playlistId: listId.value,
      morceauId: props.data.idMorceau,
      user: user.value,
      order: props.order,
    },
    (isLiked) => {
      console.log(isLiked);
      // if (isLiked) {
      //   likeState.value = "liked"
      // } else {
      //   likeState.value = "not-liked"
      // }
    }
  );
};

onMounted(() => {
  socket.value.on(
    "sync-likes-visual",
    ({ userId, isLiked, newOrder, idMorceau }) => {
      console.log("in sync ", userId, user.value.id);
      if (user.value.id == userId && newOrder == props.order && isLiked) {
        likeState.value = "liked";
      }
      if (user.value.id == userId && newOrder == props.order && !isLiked) {
        likeState.value = "not-liked";
      }
    }
  );
  if (props.data.likes.includes(user.value.id)) likeState.value = "liked";
});

const supp = () => {
  if (window.confirm("Sur de vouloir supprimer ce morceau ?")) {
    axios
      .post("/retraitMorceau", {
        idPlaylist: idPlaylist.listId.value,
        idDetails: props.data.idDetails,
      })
      .then((res) => {
        socket.value.emit("del-track-to-playlist", {
          idDetails: res.data.idDetails,
          playlistId: res.data.idPlaylist,
        });
      });
  }
};
</script>

<template>
  <td :class="{ invisible: props.invisible }" class="gauche">
    <img class="songs-avatar" :src="data.cover" />
  </td>
  <td :class="[{ lecture: props.lecture }, { invisible: props.invisible }]" class="centre">
    <p class="songs-title">{{ data.titre }}</p>
  </td>
  <td class="droite">
    <div @click="like" :class="likeState + ' like-song-container'">
      <span class="songs-likes">{{ data.likes.length }}</span>
      <img :src="heart" />
    </div>
  </td>
  <td v-if="isAdmin">
    <button class="boutonInvisible" @click="supp">
      <img class="remove-song" :src="trash" />
    </button>
  </td>
</template>

<style scoped>
.lecture {
  /*background-color: red;*/
  animation: playing 20000ms infinite linear;
}

.gauche {
	width : 15%;
	text-align: center;
}

.centre {
	width: 70%;
	text-align: center;
}

.droite {
	width: 15%;
	text-align: center;
	/* padding: 0 1em; */
}

.invisible {
  filter: opacity(0.5);
  color: rgba(255, 255, 255, 0.8);
}

@keyframes playing {
  0% {
    color: rgb(255, 0, 0);
  }

  17% {
    color: rgb(255, 255, 0);
  }

  36% {
    color: rgb(255, 255, 255);
  }

  50% {
    color: rgb(0, 255, 255);
  }

  67% {
    color: rgb(0, 0, 255);
  }

  84% {
    color: rgb(0, 0, 0);
  }

  100% {
    color: rgb(255, 0, 0);
  }
}

.left{
  text-align: left;
}
.songs-avatar {
  width: 50px;
  border-radius: 100%;
  padding: 10px 0 10px 0;
}

.songs-title {
  display: inline;
  padding-left: 5px;
}

.like-btn {
  display: inline;
}

.songs-likes {
  margin: 0;
}

.like-song-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 3px 6px;
  border-radius: 100px;
  cursor: pointer;
}

.not-liked {
  background: #310A36;
}

.liked {
  background: #ff0000;
}

.like-song-container img {
  filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg)
    brightness(100%) contrast(100%);
  width: 18px;
}

.remove-song {
  filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg)
    brightness(100%) contrast(100%);
}
</style>