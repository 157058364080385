<script setup>
import {
  ref,
  provide,
  onMounted,
  onUpdated,
  inject,
  getCurrentInstance,
} from "vue";
import { setPlayerState } from "../../services/socket";
import ProgressBar from "../player/ProgressBar.vue";

import trash from "/public/icon/trash.svg";
import heart from "/public/icon/heart.svg";

const player = ref({
  song: {},
  playing: false,
  paused: true,
  progress: 0,
  duration: 0,
  likes: [],
  songsPlayed: [],
});

const props = defineProps({
  duration: Number,
});

defineExpose({
  sendProgress,
  sendPlayer,
});

const emit = defineEmits(["emitPlayer"]);

const { listId } = inject("listIdentifyer");
const { socket } = inject("socket");

const playing = ref(false);
const progressBarComponent = ref(null);

onMounted(() => {
  socket.value.on("update-player-state", ({ newPlayerState }) => {
    player.value.duration = newPlayerState.duration;
    player.value.likes = newPlayerState.likes;
    player.value.playing = newPlayerState.playing;
    player.value.paused = newPlayerState.paused;
    player.value.progress = newPlayerState.progress;
    player.value.song = newPlayerState.song;
    player.value.songsPlayed = newPlayerState.songsPlayed;

    playing.value = newPlayerState.playing;
    emit("emitPlayer", newPlayerState);
  });
  socket.value.on("get-player-state", () => {
    if (progressBarComponent.value) {
      player.value.progress = progressBarComponent.value.getProgress();
      setPlayerState(socket, listId.value, player.value);
    }
  });
});
// onUpdated(() => {
//   socket.value.on("update-player-state", ({ newPlayerState }) => {
//     player.value = newPlayerState;
//     playing.value = newPlayerState.playing;
//     emit("emitPlayer", newPlayerState);
//   });
//   socket.value.on("get-player-state", () => {
//     if (progressBarComponent.value) {
//       player.value.progress = progressBarComponent.value.getProgress();
//       setPlayerState(socket, listId.value, player.value);
//     }
//   });
// });

const setProgress = (progress) => {
  player.value.progress = progress;
};

function sendProgress() {
  return progressBarComponent.value.getProgress();
}

function sendPlayer() {
  return player.value;
}
</script>

<template >
  <tr class="current-song-container" v-if="playing">
    <td>
      <img class="songs-avatar" :src="player.song.cover" />
    </td>
    <td class="lecture">
      <p class="songs-title">{{ player.song.titre }}</p>
    </td>
    <td>
      <div class="like-song-container">
        <span class="songs-likes">{{ player.song?.likes?.length }}</span>
        <img :src="heart" />
      </div>
    </td>
    <td></td>
  </tr>
  <tr>
    <td style="padding: 0px" colspan="4" v-if="playing">
      <ProgressBar
        ref="progressBarComponent"
        :progress="player.progress"
        :duration="player.duration"
        :state="player.paused"
      ></ProgressBar>
    </td>
  </tr>
</template>

<style>
.current-song-container {
  background-color: #ffffff54;
}

.lecture {
  /*background-color: red;*/
  animation: playing 20000ms infinite linear;
}
@keyframes playing {
  0% {
    color: rgb(255, 0, 0);
  }

  17% {
    color: rgb(255, 255, 0);
  }

  36% {
    color: rgb(255, 255, 255);
  }

  50% {
    color: rgb(0, 255, 255);
  }

  67% {
    color: rgb(0, 0, 255);
  }

  100% {
    color: rgb(255, 0, 0);
  }
}
.songs-avatar {
  width: 50px;
  border-radius: 100%;
  padding: 10px 0 10px 0;
}

.songs-title {
  display: inline;
  padding-left: 5px;
}

.like-btn {
  display: inline;
}

.songs-likes {
  margin: 0;
}

.like-song-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 3px 6px;
  border-radius: 100px;
}

.not-liked {
  background: #47747e;
}

.liked {
  background: #f97e7e;
}

.like-song-container img {
  filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg)
    brightness(100%) contrast(100%);
  width: 18px;
}

.remove-song {
  filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg)
    brightness(100%) contrast(100%);
}
</style>