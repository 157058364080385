import axios from "axios";

export async function getPlaylistSongs(listId) {
    let morceauxByPlaylist = await axios.get('/apiTest/morceauxByPlaylist/' + listId)
    morceauxByPlaylist.data.forEach(morceau => {
        morceau['likes'] = []
    });
    return morceauxByPlaylist.data
}

export async function getPlayer() {
    let getPlayer = await axios.get('/apiTest/getPlayer')
    return {
        idStreaming: getPlayer.data.idStreaming,
        plateformName: getPlayer.data.nom
    }
}

export async function initPlaylist(
    playlistId,
    afficherBouton,
    socket,
    setUser,
    setListId,
    setSocket,
    socketConnect,
    listId,
    user,
    setSongsList,
    songsList,
    lectureList,
    showPlaylist,
    showPlayListCreation,
    afficherLoader,
    // orderInPlay,
    playlistInfos
) {

    afficherBouton.value = true

    // S'il existait déjà une connexion socket alors la deconnecter
    if (socket.value) {
        socket.value.disconnect()
    }

    // Récupération des infos du user
    let res = await axios.get("/chat-user-data")
    setUser(res.data)

    // Récupération de l'id de playlist ou room
    setListId(playlistId)

    // Connexion au serveur socket si une connexion n'existe pas encore
    if (!socket.value || socket.value.connected == false) {
        setSocket(await socketConnect())
    }

    // Récéption du signal pour mettre à jour
    // la playlist après un like ou dislike
    socket.value.on("update-playlist", ({ indexInitial, indexCible, likers }) => {
        // On stock les likers dans le morceau qu'il faut
        songsList.value[indexInitial].likes = likers

        // Changement de position du morceau
        let song = songsList.value[indexInitial]
        songsList.value.splice(indexInitial, 1)
        songsList.value.splice(indexCible, 0, song)

        // Mise à jour de la liste qu'on doit lire
        // après mutation de la playlist principale
        lectureList.value = songsList.value.map(song => {
            return 'spotify:track:' + song.idSpotify
        })
    })

    // Mise à jours de la playlist après l'ajout d'un morceau
    socket.value.on("update-playlist2", ({ newSong }) => {

        songsList.value.push(newSong)
        lectureList.value.push('spotify:track:' + newSong.idSpotify)
    })

    // Mise à jours de la playlist après la suppression d'un morceau
    socket.value.on("update-playlist-remove", ({ index }) => {

        songsList.value.splice(index, 1)
        lectureList.value.splice(index, 1)
    })

    socket.value.on("get-original-playlist", ({ playlistId }) => {

        // Reset de la playlist en récupérant celle originale
        getPlaylistSongs(playlistId).then((res) => {
            setSongsList(res)
            lectureList.value = res.map(song => {
                return 'spotify:track:' + song.idSpotify
            })
        })
    })

    // On check si la playlist est déjà sur le serveur
    socket.value.emit("playlist-exists", { playlistId: listId.value }, (exists) => {
        if (!exists) {

            // Si n'existe pas Récupération de la playlist 
            // originale et l'affecter au tableau principal des morceaux
            getPlaylistSongs(listId.value).then((res) => {

                setSongsList(res)
                showPlaylist.value = true
                showPlayListCreation.value = false

                lectureList.value = songsList.value.map(song => {
                    return 'spotify:track:' + song.idSpotify
                })

                // On envoi la playlist ainsi que le user au serveur socket
                socket.value.emit('setup-playlist', { playlistId: listId.value, songs: songsList.value, user: user.value, owner: playlistInfos.value.utilisateur })
            })
        } else {

            // On envoi seulement le user et on récupère la liste qui est en ligne
            socket.value.emit("setup-user", { user: user.value, playlistId: listId.value, }, ({ songList }) => {

                setSongsList(songList)
                lectureList.value = songsList.value.map(song => {
                    return 'spotify:track:' + song.idSpotify
                })
                showPlaylist.value = true
                showPlayListCreation.value = false
            })
        }
    })
    afficherLoader.value = false
}

export async function getPlaylistInfos(playlistId) {

    // Récupérer infos sur la playlist côté Symfony
    let res = await axios.get("/getPlaylistInfos/" + playlistId)
    return res.data
}

export async function resetDetailsPlaylist(playlistId, songsList, setSongsList, socket, lectureList, stopSpotify, owner) {

    let listDeLecture = []
    // Réinitialisation de la playlist côté serveur symfony
    axios.post('detailsPlaylistReinit', { idPlaylist: playlistId.value })
        .then(response => {

            // Récupération de la playlist originale et l'affecter 
            // au tableau principal des morceaux
            getPlaylistSongs(playlistId.value).then((res) => {
                setSongsList(res)
                lectureList.value = res.map(song => {
                    return 'spotify:track:' + song.idSpotify
                })

                // Arrêt de lécture et reset côté serveur socket
                stopSpotify()
                socket.value.emit("reset-playlist", { playlistId: playlistId.value, originalSongsList: songsList.value,  owner})
            })
        })
}