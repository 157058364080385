<script setup>
import { ref, provide, onMounted, onUpdated, inject } from "vue";

const iteration = ref(0);
const progress = ref(0);
const interv = ref();

const props = defineProps({
  duration: Number,
  progress: Number,
  state: Boolean,
});

const emit = defineEmits(["playerProgress"]);

defineExpose({
  getProgress,
});

onMounted(() => {
  if (props.duration > 0) move();
});

onUpdated(() => {
  if (props.duration > 0) move();
});

const reset = () => {
  clearInterval(interv.value);
  progress.value = 0;
};

function getProgress() {
  return progress.value;
}

const move = () => {
  reset();
  if (props.progress == 0) {
    progress.value = 0.1;
  } else {
    progress.value = props.progress;
  }
  interv.value = setInterval(frame, props.duration / 1000);
};

const frame = () => {
  if (progress.value >= 100) {
    clearInterval(interv.value);
  } else {
    progress.value += 0.1;
  }
  if (props.state) {
    clearInterval(interv.value);
  }
};

const pause = () => {
  if (interv.value) clearInterval(interv.value);
};
</script>

<template>
  <div id="myProgress">
    <div id="myBar"></div>
  </div>
</template>

<style>
#myProgress {
  width: 100%;
  background-color: #dddddd47;
}

#myBar {
  width: v-bind(progress + "%");
  height: 10px;
  background-color: white;
}
</style>