import socket from 'socket.io-client'

export async function socketConnect() {
    let socketConnection = await socket(process.env.VUE_APP_SOCKET_DOMAIN || 'http://localhost:3002', {
        // secure: true,
        transports: ['websocket']
    })
    socketConnection.on('connect', function () { console.log("connected") });
    socketConnection.on("message", function (data) { console.log("update watchList with: " + data) });
    return socketConnection
}

export function setPlayerState(socket, playlistId, newPlayer = { song: {}, playing: false, progress: 0, duration: 0, likes: [], songsPlayed: [] }) {
    socket.value.emit("set-player-state", {
        playlistId,
        newPlayer: {
            song: newPlayer.song,
            playing: newPlayer.playing,
            paused: newPlayer.paused,
            progress: newPlayer.progress,
            duration: newPlayer.duration,
            likes: newPlayer.likes,
            songsPlayed: newPlayer.songsPlayed
        },
    });
}
