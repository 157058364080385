<script setup>
import { ref, inject } from 'vue'
import axios from "axios";
import SongAjout from "../song/SongAjout";
import Loader from "../Loader";

const props = defineProps({
  show: Boolean
})


// A "ref" is a reactive data source that stores a value.
// Technically, we don't need to wrap the string with ref()
// in order to display it, but we will see in the next
// example why it is needed if we ever intend to change
// the value.
const message = ref('Hello World!')
const messageBouton = ref("Ajouter un morceau")
const afficherForm = ref(false)
const afficherResult = ref(false)
const afficherLoader = ref(false)
const titre = ref('')
const artist = ref('')
const rslt = ref([])
let isrc = ref('')
let temp = []
const idPlaylist = inject("listIdentifyer")


const afficher = () => {
  afficherForm.value = (!afficherForm.value)
  if (!afficherForm.value) {
    messageBouton.value = "Ajouter un morceau"
  } else {
    messageBouton.value = "Masquer"
  }
}

const search = () => {
  afficherLoader.value = true
  console.log("titre : " + titre.value)
  afficherResult.value = true
  axios
    .post('/apiTest/search', { titre: titre.value ? titre.value : "*", artiste: artist.value })
    .then(res => {
      rslt.value = res.data
      afficherLoader.value = false
    })
    .catch(error => {
      console.log(error)
    })
}

// Ajouter un morceau
const ajoutMorceauDansPlaylist = () => {
  const formAjout = document.getElementById("formAjout");
  formAjout.addEventListener("submit", (e) => {
    e.preventDefault()
  })
  if (isrc.value === "") {
    alert("Merci de sélectionner un morceau")
  } else {
    axios
      .post("/ajoutMorceauIsrc", { id_playlist: idPlaylist.listId.value, track: isrc.value })
      .then(res => {
        console.log(res)
      })
  }
  alert(idPlaylist.listId.value + ' - ' + isrc.value)
}

const effacerRecherche = () => {
  titre.value = '';
  artist.value = '';
  rslt.value = [];
  afficherResult.value = false;
  afficherForm.value = false;
  messageBouton.value = "Ajouter un morceau"
}

</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="search-form">
              <div class="search-form-field">
                <div class="search-form-field-title">
                  <input id="titre" required name="titre" placeholder="Titre..." v-model="titre" v-on:keyup.enter="search"
                    class="form-control">
                </div>
                <div class="search-form-field-artist">
                  <input id="artist" name="artist" v-model="artist" placeholder="Artiste..." v-on:keyup.enter="search"
                    class="form-control">
                </div>

              </div>
            </div>
          </div>

          <div class="modal-body">
            <div v-if="afficherResult">
              <Loader v-if="afficherLoader"></Loader>
              <table>
                <tbody name="fade" is="vue:transition-group">
                  <tr v-for="(track, index) in           rslt          " :key="track">
                    <SongAjout :idPlaylist="idPlaylist.listId.value" :index="index" :key="track" :track="track"
                      @effacer="effacerRecherche; $emit('close');">
                    </SongAjout>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="modal-footer">
            <div id="result"></div>
            <div class="search-form-action" @click=" search ">
              <!-- <button @click="search" class="btn-primary centrer">Rechercher</button> -->
              <span>
                <font-awesome-icon icon="fa-solid fa-magnifying-glass-arrow-right" />
                Chercher
              </span>
            </div>
          </div>
          <div class="close-modal"><font-awesome-icon icon="fa-solid fa-times-circle" @click="$emit('close')" /></div>
          <!-- <button class="modal-default-button" @click="$emit('close')">Annuler</button> -->
        </div>
      </div>
    </div>
  </Transition>
</template >

<style>
.search-form input {
  background: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #ffffff;
  color: white;
}

.search-form input::placeholder {
  color: rgba(255, 255, 255, 0.753);
}

.search-form input:focus {
  background: none;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #ffffff;
  box-shadow: none;
  scale: 1.05;
  color: white;
}

.modal-container {
  position: relative;
}

.modal-container .close-modal svg {
  height: 40px;
}

.modal-container .close-modal {
  position: absolute;
  top: -10px;
  right: -10px;
}

.custom-icon {
  font-size: 3rem;
}

.search-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

}

.search-form * {
  transition: .1s;
}

.search-form-field {
  display: flex;
  align-items: flex-end;
  align-content: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  flex-wrap: nowrap;
  margin-bottom: 15px;
  margin-top: 20px;
}

.search-form-field div {
  width: 41%;
}

.search-form-action {
  width: 100%;
  padding: 20px 10px;
  background-color: #12b7b7;
  border-radius: 10px;
  /* width: 10% !important; */
  /* text-align: center; */
}

.search-form-action span {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  font-size: 1.4rem !important;
}

.search-form-action svg {
  height: 31px;
  margin: 0;
  margin-right: 8px;
}

.search-form-action:hover svg {
  /* height: 45px;
  cursor: pointer;
  color: #310A36; */
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 5%;
}

.modal-container {
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #72207E;
  /*border-radius: 2px;*/
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 10px;
  /*border: 2px #D90148 solid;*/
  max-height: 800px;
}

.modal-header {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 10px 0;
  overflow-y: scroll;
  max-height: 300px;
}

.modal-body::-webkit-scrollbar {
  width: 5px;
}

.modal-body::-webkit-scrollbar-thumb {
  background: #42b983;
  border-radius: 10px;
}

.modal-body table {
  border: none;
}

.modal-default-button {
  float: right;
  color: white;
  background-color: #12B7B7;
  border: 0px;
  border-radius: 10px;
  padding: 5px 15px;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-footer {
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .search-form-field .search-form-field-title {
    margin-bottom: 10px;
  }

  .modal-container {
    width: 100%;
    margin: 10px auto;
    padding: 15px;
    background-color: #72207E;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    border-radius: 13px;
    max-height: 100%;
  }

  .search-form-field {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
    margin-bottom: 15px;
  }

  .search-form-field div {
    width: 100%;
  }

  .search-form-action svg {
    /* height: 55px;
    margin-top: 20px; */
  }
}
</style>