/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

// start the Stimulus application
import './bootstrap';

import { createApp } from 'vue'
import VueApp from './VueApp.vue'

import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret, faPlay, faForwardStep, faBackwardStep, faPause, faStop, faFloppyDisk, faArrowRotateLeft, faCirclePlus, faMagnifyingGlassArrowRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add([faUserSecret, faPlay, faForwardStep, faBackwardStep, faPause, faStop, faFloppyDisk, faArrowRotateLeft, faCirclePlus, faMagnifyingGlassArrowRight, faTimesCircle])


const app = createApp(VueApp)
    .component('font-awesome-icon', FontAwesomeIcon)

app.mount('#vuePlayList')
